import { ChangeEvent } from "react"
import { Tip, TipType } from "../Tip/Tip"
import s from "./UpdateStoks.module.css"
import JSZip from "jszip"
import readXlsxFile from "read-excel-file"
import { windowLock } from "../../utils/loader"

const prepareXLSX = async (file: File) => {
  windowLock()
  const dataFromXslx = await readXlsxFile<{
    name: string
    amount: number
    price: number
  }>(file, {
    schema: schemaDataFromKatren,
  })

  return dataFromXslx.rows
}

type UploadMultipleFilesFromKatrenProps = {
  deleteDataFromKatren: () => void
  isDataFromKatrenUploaded: boolean
  uploadDataFromKatren: (
    data: { name: string; amount: number; price: number }[],
  ) => void
}

export const UploadFileFromKatren = ({
  deleteDataFromKatren,
  isDataFromKatrenUploaded,
  uploadDataFromKatren,
}: UploadMultipleFilesFromKatrenProps) => {
  return (
    <div className={s.uploadFileWrap}>
      {isDataFromKatrenUploaded ? (
        <div>
          <button onClick={deleteDataFromKatren}>
            удалить файл цен и остатков с Катрена
          </button>
        </div>
      ) : (
        <div>
          <label className={s.ktrnFileLabel} htmlFor={s.priceFromKtrnInp}>
            Загрузить файл цен и остатков с Катрена
          </label>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const file = e.target.files?.[0]
              if (file) {
                prepareXLSX(file).then(uploadDataFromKatren)
              }
            }}
            type="file"
            accept=".xlsx"
            id={s.priceFromKtrnInp}
          />
        </div>
      )}
      <Tip
        content={
          <span>
            <b>Внимание!</b>
            <p>
              Файл цен и остатков должен быть формата <b>XLSX</b> и содержать
              следующие <b>обязательные</b> колонки:
            </p>
            <ol className={s.list}>
              <li>
                <b>Название</b> - название продукта
              </li>
              <li>
                <b>Остаток</b> - остаток на складе катрена
              </li>
              <li>
                <b>Цена за 1 уп.</b> - цена
              </li>
            </ol>
          </span>
        }
        type={TipType.WARNING}
      />
    </div>
  )
}

const schemaDataFromKatren = {
  Название: {
    prop: "name",
    type: String,
  },
  Остаток: {
    prop: "amount",
    type: Number,
  },
  "Цена за 1 уп.": {
    prop: "price",
    type: Number,
  },
}
