import React, { useEffect, useState } from "react"

import s from "./modal.module.css"

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  header?: string
  onOk: () => void
  content: string | JSX.Element
  size?: "default" | "auto" | "big"
  isOkDisabled?: boolean
  okContent?: string
}
export const Modal = ({
  isOpen,
  onClose,
  header,
  onOk,
  content,
  size,
  isOkDisabled,
  okContent,
}: ModalProps) => {
  const [open, setOpen] = useState(!!isOpen)
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  if (!isOpen || !open) return null
  const onCloseModal = () => {
    setOpen(false)
    onClose()
  }
  const onConfirm = () => {
    setOpen(false)
    onOk()
  }
  return (
    <div
      className={s.modalWrapper}
      onClick={(e) => {
        if (e.target === e.currentTarget) onCloseModal()
      }}
    >
      <div className={`${s.modal} ${s[size || "auto"]}`}>
        <div className={s.header}>
          <div className={s.title}>{header}</div>
          <div className={s.closeBtn} onClick={onCloseModal}>
            X
          </div>
        </div>
        <div className={s.content}>
          <span>{content}</span>
        </div>
        <div className={s.footer}>
          <button onClick={onCloseModal}>Отмена</button>
          <button onClick={onConfirm} disabled={isOkDisabled}>
            {okContent || "ОК"}
          </button>
        </div>
      </div>
    </div>
  )
}
