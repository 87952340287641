import { useState } from "react"
import { Tab } from "../Tab/Tab"
import s from "./tabs.module.css"

type tabsProps = {
  children: any[]
}
export const Tabs = ({ children }: tabsProps) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className={s.tabs}>
      <div className={s.tabsHeader}>
        {children.map((TabEl, i) => {
          return (
            <div
              className={`${s.tabsHeader__el} ${
                activeTab === i ? s.active : ""
              }`}
              key={"tabBtn" + TabEl.props.name + i}
              onClick={() => {
                setActiveTab(i)
              }}
            >
              {TabEl.props.name}
            </div>
          )
        })}
      </div>
      <div>
        {children.map((TabEl, i) => {
          return (
            <Tab
              key={"tabcontent" + TabEl.props.name + i}
              active={activeTab === i}
            >
              {TabEl}
            </Tab>
          )
        })}
      </div>
    </div>
  )
}
