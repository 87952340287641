import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const authReducer = createSlice({
  name: "auth",
  initialState: {
    name: "",
    password: "",
  },
  reducers: {
    onChangeName: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
  },
})

export default authReducer.reducer

export const { onChangeName } = authReducer.actions
