import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  lastUpdated: string
  status?: "Ошибка" | "В процессе" | "Успешно" | "Ошибка. Не валидные куки"
}

const initialState: initialStateType = {
  lastUpdated: "",
}

const updateStoksIconSlice = createSlice({
  name: "updateStoksIcon",
  initialState,
  reducers: {
    setUpdateStoksIconState: (
      state,
      action: PayloadAction<{
        lastUpdated: string
        status: "Ошибка" | "В процессе" | "Успешно" | "Ошибка. Не валидные куки"
      }>,
    ) => {
      state.lastUpdated = action.payload.lastUpdated
      state.status = action.payload.status
    },
    setStatus: (
      state,
      action: PayloadAction<
        "Ошибка" | "В процессе" | "Успешно" | "Ошибка. Не валидные куки"
      >,
    ) => {
      state.lastUpdated = action.payload
    },
  },
})

export const selectUpdateStoksIconStore = (state: RootState) =>
  state.updateStoksIcon

export default updateStoksIconSlice.reducer

export const { setUpdateStoksIconState, setStatus } =
  updateStoksIconSlice.actions
