import React, { useState } from "react"
import s from "./NotificationServiceStyles.module.css"
import { NotificationType } from "./NotificationTypes"
export const Notification = ({
  level,
  message,
  errorBody,
}: NotificationType) => {
  const levelClass: "success" | "error" | "info" = level || "info"
  const [isVisible, setVisible] = useState(true)
  if (!isVisible) return null
  return (
    <div className={`${levelClass} ${s.notification}`}>
      <div>
        {levelClass === "success"
          ? "Успешно"
          : levelClass === "error"
          ? "Ошибка"
          : "Информация"}
      </div>
      <div className={s.message}>{message}</div>
      <div>
        {errorBody ? (
          <div
            className={s.copyErr}
            onClick={() => {
              navigator.clipboard.writeText(errorBody)
              setVisible(false)
            }}
          >
            Скопировать ошибку
          </div>
        ) : (
          ""
        )}
      </div>
      <div className={s.closeBtn} onClick={() => setVisible(false)}>
        X
      </div>
    </div>
  )
}
