import React, { useRef } from "react"
import readXlsxFile, { Schema } from "read-excel-file"
import writeXlsxFile from "write-excel-file"
import { IOrder } from "../../app/reducers/ordersReducer"
import s from "./FormXlsxForOrderIcon.module.css"
import { NotificationService } from "../../features/NotificationService/NotificationService"

const SCHEMA: Schema = {
  Картинка: {
    prop: "img",
  },
  Артикул: {
    prop: "article",
  },
  Наименование: {
    prop: "name",
  },
  Штрихкод: {
    prop: "barCode",
  },
  Цена: {
    prop: "price",
  },
  РРЦ: {
    prop: "rrc",
  },
  Остаток: {
    prop: "amount",
  },
  "Введите количество": {
    prop: "count",
  },
  "Сумма итого": {
    prop: "sum",
  },
}

const SchemaWrite = [
  {
    column: "Картинка",
    value: (order: any) => order.img,
  },
  {
    column: "Артикул",
    value: (order: any) => order.article,
  },
  {
    column: "Наименование",
    type: String,
    value: (order: any) => order.name,
  },
  {
    column: "Штрихкод",
    type: Number,
    value: (order: any) => order.barCode,
  },
  {
    column: "Цена",
    type: Number,
    value: (order: any) => order.price,
  },
  {
    column: "РРЦ",
    type: Number,
    value: (order: any) => order.rrc,
  },
  {
    column: "Остаток",
    type: Number,
    value: (order: any) => order.amount,
  },
  {
    column: "Введите количество",
    type: Number,
    value: (order: any) => order.count,
  },
  {
    column: "Сумма итого",
    type: Number,
    value: (order: any) => order.sum,
  },
]
export const FormXlsxForOrderIcon = ({ orders }: { orders?: IOrder[] }) => {
  const [file, setFileData] = React.useState<any>()
  return (
    <div className={s.content}>
      <strong>Сформировать файл для заказа на ICON</strong>
      <div className={s.pinIconFile}>
        <label className={s.iconFileLabel} htmlFor={s.iconFileInp}>
          Загрузить файл c ценами
        </label>
        <input
          onChange={(e) => {
            console.log(e.target.value)
            e.target.files && setFileData(e.target.files?.[0])
          }}
          disabled={!!file}
          type="file"
          accept=".xlsx"
          id={s.iconFileInp}
        />
        {file?.name}
      </div>
      <button
        disabled={!orders?.length || !file}
        onClick={() => {
          file &&
            readXlsxFile<any>(file, { schema: SCHEMA }).then((data) => {
              const rows = data.rows
              const res: any = new Map()
              orders?.forEach((el) => {
                if (el.article.includes("NIC-ICON")) {
                  const articleArr = el.article.split("-").slice(2)
                  const count = +articleArr[articleArr.length - 1]
                  const article = articleArr
                    .slice(0, articleArr.length - 1)
                    .join("-")
                  if (res.has(article)) {
                    const row = res.get(article)
                    const resultRow = {
                      ...row,
                      count: row.count + count,
                      sum: row.sum + row.price * count,
                    }
                    res.set(article, resultRow)
                  } else {
                    const row = rows.find(
                      (row) => row.article && String(row.article) === article,
                    )
                    if (row) {
                      const resultRow = {
                        ...row,
                        count,
                        sum: row.price * count,
                      }
                      res.set(article, resultRow)
                    } else {
                      NotificationService.showNotification({
                        message: `Товар с артикулом ${article} не найден в файле цен`,
                        level: "error",
                        errorBody: article,
                      })
                    }
                  }
                }
              })

              const filtredRes = Array.from(res.values())
              filtredRes &&
                writeXlsxFile(filtredRes, {
                  schema: SchemaWrite,
                  fileName: "orders.xlsx",
                })
            })
        }}
      >
        Сформировать файл
      </button>
    </div>
  )
}
