import { ChangeEvent, useState } from "react"
import { Tip, TipType } from "../Tip/Tip"
import s from "./UpdateStoks.module.css"
import JSZip from "jszip"
import readXlsxFile from "read-excel-file"
import { Modal } from "../../features/Modal/Modal"
import { windowLock } from "../../utils/loader"

const prepareZip = async (file: File) => {
  const zip = new JSZip()
  const buffer = await file.arrayBuffer()
  await zip.loadAsync(buffer)
  const files = zip.files
  return files
}

const prepareXLSX = async (
  files: { [key: string]: JSZip.JSZipObject },
  mainFileName: string,
) => {
  windowLock()
  const result: {
    [key: string]: { name: string; amount: number; price: number }
  } = {}
  for (const key in files) {
    const file = files[key]
    // Проверка, что файл является обычным файлом (а не подкаталогом)
    if (!file.dir && key.split(".").pop() === "xlsx") {
      const data = await file.async("arraybuffer")
      const dataFromXslx = await readXlsxFile<any>(data, {
        schema: schemaDataFromKatren,
      })
      if (dataFromXslx.rows) {
        dataFromXslx.rows.forEach((el) => {
          const { name, amount, price } = el
          if (result[name]) {
            if (key === mainFileName) result[name].amount = amount
            result[name].price =
              result[name].price > price ? result[name].price : price
          } else {
            if (key === mainFileName) {
              result[name] = { name, amount, price }
            } else result[name] = { name, amount: 0, price }
          }
        })
      }
    }
  }

  return Object.values(result)
}

type UploadMultipleFilesFromKatrenProps = {
  deleteDataFromKatren: () => void
  isDataFromKatrenUploaded: boolean
  uploadDataFromKatren: (
    data: { name: string; amount: number; price: number }[],
  ) => void
}

export const UploadMultipleFilesFromKatren = ({
  deleteDataFromKatren,
  isDataFromKatrenUploaded,
  uploadDataFromKatren,
}: UploadMultipleFilesFromKatrenProps) => {
  const [isOpen, setOpen] = useState(false)
  const [files, setFiles] = useState<{ [key: string]: JSZip.JSZipObject }>({})
  const [mainFileName, setMainFileName] = useState("")
  return (
    <div className={s.uploadFileWrap}>
      {isDataFromKatrenUploaded ? (
        <div>
          <button onClick={deleteDataFromKatren}>
            удалить файл цен и остатков с Катрена
          </button>
        </div>
      ) : (
        <div>
          <label className={s.ktrnFileLabel} htmlFor={s.pricesFromKtrnInp}>
            Загрузить zip-файл цен и остатков с Катрена
          </label>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.target.files &&
                prepareZip(e.target.files[0]).then((files) => {
                  setFiles(files)
                  setOpen(true)
                  // uploadDataFromKatren(data)
                })
            }}
            type="file"
            accept=".zip"
            id={s.pricesFromKtrnInp}
          />
        </div>
      )}
      <Tip
        content={
          <span>
            <b>Внимание!</b>
            <p>
              Прикрепляемый файл должен быть в формате <b>ZIP</b>. В архиве
              должны быть файлы цен и остатков в формате <b>XLSX</b>. XLSX-файлы
              должны содержать следующие <b>обязательные</b> колонки:
            </p>
            <ol className={s.list}>
              <li>
                <b>Название</b> - название продукта
              </li>
              <li>
                <b>Остаток</b> - остаток на складе катрена
              </li>
              <li>
                <b>Цена за 1 уп.</b> - цена
              </li>
            </ol>
          </span>
        }
        type={TipType.WARNING}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false)
          setFiles({})
          setMainFileName("")
        }}
        onOk={() => {
          prepareXLSX(files, mainFileName).then(uploadDataFromKatren)
        }}
        isOkDisabled={!mainFileName}
        header="Выберите файл из которого загрузить остатки"
        content={
          <div className={s.radioWrap}>
            {Object.keys(files).map((key) => (
              <div key={key}>
                <input
                  type="radio"
                  id={key}
                  name={key}
                  value={key}
                  onChange={() => {
                    setMainFileName(key)
                  }}
                  checked={key === mainFileName}
                />
                <label htmlFor={key}> {key}</label>
              </div>
            ))}
          </div>
        }
      ></Modal>
    </div>
  )
}

const schemaDataFromKatren = {
  Название: {
    prop: "name",
    type: String,
  },
  Остаток: {
    prop: "amount",
    type: Number,
  },
  "Цена за 1 уп.": {
    prop: "price",
    type: Number,
  },
}
