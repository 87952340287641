import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  isFileUploaded: boolean
  storages: {
    name: string
    officeId: number
    id: number
  }[]
}

const initialState: initialStateType = {
  isFileUploaded: false,
  storages: [],
}

const updateStoksSlice = createSlice({
  name: "updateStoks",
  initialState,
  reducers: {
    fileForUpdateStoksUpload: (state, action) => {
      state.isFileUploaded = action.payload
    },
    setStorages: (
      state,
      action: PayloadAction<{ name: string; officeId: number; id: number }[]>,
    ) => {
      state.storages = action.payload
    },
  },
})

export const selectUpdateStoksStore = (state: RootState) =>
  state.updateStoksStore

export default updateStoksSlice.reducer

export const { fileForUpdateStoksUpload, setStorages } =
  updateStoksSlice.actions
