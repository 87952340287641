import React, { ChangeEvent, useEffect, useState } from "react"
import s from "./UpdateStoksIcon.module.css"
import { AppDispatch } from "../../app/store"
import BaseApi from "../../api/api"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import { Modal } from "../../features/Modal/Modal"
import {
  selectUpdateStoksIconStore,
  setUpdateStoksIconState,
} from "../../app/reducers/updateStoksIcon"

const updatePricesICON = (cookie: string) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.updatePricesICON(cookie)
      .then((response) => {
        dispatch(updateStoksState())
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        dispatch(updateStoksState())
        // Обработайте ошибку загрузки файла
      })
  }
}

const updateStoksState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.updateStoksICONState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksIconState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
        dispatch(
          setUpdateStoksIconState({
            lastUpdated: "",
            status: "Ошибка",
          }),
        )
      })
  }
}

export const UpdateStoksIcon = () => {
  const dispatch = useAppDispatch()
  const [isOpenModalUpdateStoks, setOpenModalUpdateStoks] = useState(false)
  const [cookie, setCookie] = useState("")
  useEffect(() => {
    dispatch(updateStoksState())
  }, [])
  const updateStoksStore = useAppSelector(selectUpdateStoksIconStore)

  const { status, lastUpdated } = updateStoksStore
  return (
    <div className={s.updateStoksWrapper}>
      <h5>Загрузка остатков и обновление цен</h5>
      <h6>
        Последнее обновление:{" "}
        {lastUpdated && new Date(lastUpdated).toLocaleString()}
      </h6>
      <div>
        <div className={s.content}>
          <div className={s.cookie}>
            <h6>вставьте куки:</h6>
            <textarea
              value={cookie}
              onChange={(e) => setCookie(e.target.value)}
            ></textarea>
          </div>
          <div>
            <h6>статус:</h6>
            <div
              className={`${s.status} ${
                status &&
                ["Ошибка. Не валидные куки", "Ошибка"].includes(status) &&
                s.error
              }`}
            >
              {status}
            </div>
          </div>
          <button
            className={s.btn}
            disabled={!cookie}
            onClick={() => setOpenModalUpdateStoks(true)}
          >
            Обновить цены и остатки
          </button>
        </div>
      </div>
      <Modal
        isOpen={isOpenModalUpdateStoks}
        header="Подтвердите действие"
        content={"Обновить остатки и цены"}
        onClose={() => setOpenModalUpdateStoks(false)}
        onOk={() => {
          dispatch(updatePricesICON(cookie))
        }}
      />
    </div>
  )
}
