export const AttentionIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <g>
          <path d="M13 6.984a1 1 0 1 0-2 0v6.158a1 1 0 1 0 2 0V6.984ZM13.111 16.253a1.111 1.111 0 1 1-2.222 0 1.111 1.111 0 0 1 2.222 0Z"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1ZM3 12a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}
