import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { Info } from "../Info/Info"
import { Upload } from "../Upload/Upload"
import { UpdateStoksOld } from "../UpdateStoksOld/UpdateStoks"
import { NewOrders, getOrdersState } from "../NewOrders/NewOrders"
import { Supplies } from "../Supplies/Supplies"
import { UpdateStoksIcon } from "../UpdateStoksIcon/UpdateStoksIcon"
import { Settings } from "../Settings/Settings"
import { UpdateStoksKatren } from "../UpdateStoksKatren/UpdateStoksKatren"
import { useEffect } from "react"
import { useAppDispatch } from "../../app/hooks"

export const Home = ({}) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getOrdersState())
  }, [])
  return (
    <div className="homePage">
      <div className="SHOP_NAME">МАГАЗИН БАБКИНА</div>
      <Tabs>
        {/* <TabContent name={"Поиск по артикулам"}>
          <Info />
        </TabContent> */}
        {/* <TabContent name={"Загрузка карточек"}>
          <Upload />
        </TabContent> 
        <TabContent name={"Обновить остатки Katren Old"}>
          <UpdateStoksOld />
        </TabContent>
        */}
        <TabContent name={"Обновить остатки Katren"}>
          <UpdateStoksKatren />
        </TabContent>
        <TabContent name={"Обновить остатки ICON"}>
          <UpdateStoksIcon />
        </TabContent>
        <TabContent name={"Сборочные задания"}>
          <NewOrders />
        </TabContent>
        <TabContent name={"Поставки"}>
          <Supplies />
        </TabContent>
        <TabContent name={"Настройки"}>
          <Settings />
        </TabContent>
      </Tabs>
    </div>
  )
}
