import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type initialStateType = {
  isFileDistrUploaded: boolean
  isParserRunning: boolean | "sucsess"
  isCardsFormed: boolean
  notFoundItems?: string[]
}

const initialState: initialStateType = {
  isFileDistrUploaded: false,
  isParserRunning: false,
  isCardsFormed: false,
}

const generalKtrnSlice = createSlice({
  name: "generalKtrn",
  initialState,
  reducers: {
    fileDistrUpload: (state, action: PayloadAction<boolean>) => {
      state.isFileDistrUploaded = action.payload
    },
    setParsingState: (state, action: PayloadAction<boolean | "sucsess">) => {
      state.isParserRunning = action.payload
    },
    setCardsFormedState: (state, action: PayloadAction<boolean>) => {
      state.isCardsFormed = action.payload
    },
    setNotFoundedItems: (state, action: PayloadAction<string[]>) => {
      state.notFoundItems = action.payload
    },
  },
})

export const selectGeneralKtrn = (state: RootState) => state.generalKtrn

export default generalKtrnSlice.reducer

export const {
  fileDistrUpload,
  setParsingState,
  setCardsFormedState,
  setNotFoundedItems,
} = generalKtrnSlice.actions
