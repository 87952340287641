import { useEffect, useState } from "react"
import s from "./UpdateStoks.module.css"
import { AppDispatch } from "../../app/store"
import BaseApi from "../../api/api"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { NotificationService } from "../../features/NotificationService/NotificationService"
import {
  selectUpdateStoksKatrenStore,
  setUpdateStoksKatrenState,
} from "../../app/reducers/updateStoksKatren"
import { Tabs } from "../Tabs/Tabs"
import { TabContent } from "../Tab/TabContent"
import { UploadMultipleFilesFromKatren } from "./UploadMultipleFilesFromKatren"
import { UploadFileFromKatren } from "./UploadFileFromKatren"
import { selectOrdersStore } from "../../app/reducers/ordersReducer"
import { WildberriesTab } from "./WbTabUpdateStoks"
import { OzonTabUpdateStoks } from "./OzonTabUpdateStoks"

const uploadDataFromKatren = (
  data: { name: string; amount: number; price: number }[],
) => {
  return async (dispatch: AppDispatch) => {
    BaseApi.uploadDataFromKatren(data)
      .then((response) => {
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  }
}

const deleteDataFromKatren = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.deleteDataFromKatren()
      .then((response) => {
        console.log(response.data)
        response.data?.state &&
          dispatch(setUpdateStoksKatrenState(response.data.state))
        NotificationService.showNotification({
          message: response.data.message,
          level: (response.data.success && "success") || "error",
        })
      })
      .catch((error) => {
        console.error(error)
        error?.response?.data?.state &&
          dispatch(setUpdateStoksKatrenState(error.response.data.state))
        // Обработайте ошибку загрузки файла
      })
  }
}

const updateStoksState = () => {
  return async (dispatch: AppDispatch) => {
    BaseApi.katrenUpdateStoksState()
      .then((response) => {
        if (response.data) {
          dispatch(setUpdateStoksKatrenState(response.data))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const UpdateStoksKatren = () => {
  const dispatch = useAppDispatch()
  useState(false)
  useEffect(() => {
    dispatch(updateStoksState())
  }, [])
  const updateStoksStore = useAppSelector(selectUpdateStoksKatrenStore)
  const ordersStore = useAppSelector(selectOrdersStore)
  const { storages, ozonStorages } = ordersStore
  const {
    lastStoksUpdated,
    lastPricesUpdated,
    status,
    isCurrentDataUploaded,
    isDataFromKatrenUploaded,
    ozon,
  } = updateStoksStore
  return (
    <div className={s.updateStoksWrapper}>
      <h5>Загрузка остатков и обновление цен</h5>
      <div>
        <div className={s.uploadFilesWrap}>
          <Tabs>
            <TabContent name={"Загрузить файл цен и остатков с Катрена"}>
              <UploadFileFromKatren
                deleteDataFromKatren={() => {
                  dispatch(deleteDataFromKatren())
                }}
                uploadDataFromKatren={(
                  data: { name: string; amount: number; price: number }[],
                ) => {
                  dispatch(uploadDataFromKatren(data))
                }}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
              />
            </TabContent>
            <TabContent name="Загрузить несколько файлов цен и остатков с Катрена">
              <UploadMultipleFilesFromKatren
                deleteDataFromKatren={() => {
                  dispatch(deleteDataFromKatren())
                }}
                uploadDataFromKatren={(
                  data: { name: string; amount: number; price: number }[],
                ) => {
                  dispatch(uploadDataFromKatren(data))
                }}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
              />
            </TabContent>
          </Tabs>
        </div>
        <div>
          <Tabs>
            <TabContent name={"wildberries"}>
              <WildberriesTab
                lastStoksUpdated={lastStoksUpdated}
                lastPricesUpdated={lastPricesUpdated}
                isCurrentDataUploaded={isCurrentDataUploaded}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
                status={status}
                storages={storages}
              />
            </TabContent>
            <TabContent name={"ozon"}>
              <OzonTabUpdateStoks
                lastStoksUpdated={ozon?.lastStoksUpdated ?? ""}
                lastPricesUpdated={ozon?.lastPricesUpdated ?? ""}
                isCurrentDataUploaded={ozon?.isCurrentDataUploaded ?? false}
                isDataFromKatrenUploaded={isDataFromKatrenUploaded}
                status={ozon?.status}
                storages={ozonStorages}
              />
            </TabContent>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
