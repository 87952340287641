import React from "react"

type ModalOrderListProps = {
  formOrders: {
    GoodName: string
    GoodCode: string
    Quantity: number
  }[]
}
export const ModalOrderList = ({ formOrders }: ModalOrderListProps) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>GoodName</th>
            <th>GoodCode</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {formOrders?.map((el, i) => {
            return (
              <tr key={"order-list-" + i}>
                <td>{el.GoodName}</td>
                <td>{el.GoodCode}</td>
                <td>{el.Quantity}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
