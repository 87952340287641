import { useDispatch, useSelector } from "react-redux"
import { onChangeName } from "../app/reducers/authReducer"
import { useAppSelector } from "../app/hooks"

import s from "./Auth.module.css"
import { useState } from "react"
import BaseApi from "../api/api"
import { redirect } from "react-router-dom"

export const Auth = () => {
  // const name = useAppSelector((state) => state?.auth?.name)
  // const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [pass, setPass] = useState("")
  const login = () => {
    BaseApi.auth({
      username: name,
      password: pass,
    })
  }
  if (localStorage.getItem("token")) {
    location.replace("/")
    return <div></div>
  }

  return (
    <div className={s.authPage}>
      <div className={s.formWrapper}>
        <label htmlFor="authName" className={s.label}>
          Логин
        </label>
        <input
          type="text"
          name="name"
          id="authName"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={(s.logn, s.input)}
        />
        <label htmlFor="authPass" className={s.label}>
          Пароль
        </label>
        <input
          type="password"
          name="password"
          id="authPass"
          className={(s.password, s.input)}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
        />
        <button className={s.btn} onClick={login}>
          Войти
        </button>
      </div>
    </div>
  )
}
