import React from "react"
import s from "./Tip.module.css"
import { AttentionIcon } from "../Icons/AttentionIcon"
import { InfoIcon } from "../Icons/InfoIcon"

export enum TipType {
  INFO = "info",
  WARNING = "warning",
}
type TipProps = {
  content: String | JSX.Element
  type: TipType
}
export const Tip = ({ content: text, type }: TipProps) => {
  let Icon = type === TipType.INFO ? InfoIcon : AttentionIcon
  const typeClass = type === TipType.INFO ? s.info : s.warning
  return (
    <div className={`${s.tip} ${typeClass}`}>
      <div className={s.content}>
        <div>
          <Icon />
        </div>
        <div>{text}</div>
      </div>
    </div>
  )
}
