import { useState } from "react"
import s from "./Settings.module.css"

export const Calculator = (props: {
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  sale?: number
  priemka?: number
  ekv?: number
  mila?: number
  shop: "ICON" | "KATREN" | "KATREN_OZON"
}) => {
  const {
    margin,
    profit,
    logistic,
    ff,
    coef,
    tax,
    sale = 0,
    shop,
    priemka,
    ekv,
    mila,
  } = props
  const [priceParams, setPriceParams] = useState<{
    price?: number
  }>()
  const [result, setResult] = useState<{ price: number; old_price: number }>()
  const calc = shop === "KATREN_OZON" ? calcPriceForOzon : calcPrice
  return (
    <div>
      <h5>Калькулятор</h5>
      <div>
        <div>
          <div className={s.inputWrap}>
            <label htmlFor="price">Входная цена</label>
            <input
              value={priceParams?.price}
              onChange={(e) =>
                setPriceParams({
                  ...priceParams,
                  price: Number(e.target.value),
                })
              }
              name="price"
              type="number"
              min="0"
            />
          </div>
          <div className={s.inputWrap}>
            <label htmlFor="profit">Скидка</label>
            <input
              value={sale * 100}
              disabled
              name="sale"
              type="number"
              min="0"
              max="100"
            />
          </div>
          <div className={s.calcPriceWrap}>
            <button
              onClick={() =>
                setResult(
                  calc({
                    ...priceParams,
                    margin,
                    profit,
                    logistic,
                    ff,
                    coef,
                    tax,
                    sale: sale * 100,
                    priemka,
                    ekv,
                    mila,
                  }),
                )
              }
            >
              Рассчитать
            </button>
            <div style={{ display: "flex" }}>
              <b style={{ fontSize: "25px" }}>{result?.price}</b>{" "}
              {(sale && <s>{result?.old_price}</s>) || ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const calcPrice = (props: {
  sale?: number
  price?: number
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  priemka?: number
  ekv?: number
  mila?: number
}): { price: number; old_price: number } => {
  const {
    sale = 0,
    price = 0,
    margin = 0,
    profit = 0,
    logistic = 0,
    ff = 0,
    coef = 0,
    tax = 0,
  } = props
  const LOGISTIC = Math.max(logistic, 0)
  const FF = Math.max(ff, 0)
  const KOEF = Math.max(coef, 0)
  const NALOG = Math.max(tax, 0)
  const MIN_CONST_PRIBL = Math.max(profit, 0)
  const MARZA = Math.max(margin, 0)
  const SALE = Math.max(sale, 0) / 100

  const PRIBL = Math.max(MARZA * price, MIN_CONST_PRIBL)
  const KASSA = PRIBL + FF + price
  const currentPrice = Math.ceil((KASSA + LOGISTIC) / (1 - KOEF - NALOG))

  return {
    price: currentPrice,
    old_price: Math.ceil(currentPrice / (1 - SALE)),
  }
}

export const calcPriceForOzon = (props: {
  sale?: number
  price?: number
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  priemka?: number
  ekv?: number
  mila?: number
}): { price: number; old_price: number } => {
  const {
    sale = 0,
    price = 0,
    margin = 0,
    profit = 0,
    logistic = 0,
    ff = 0,
    coef = 0,
    tax = 0,
    priemka = 0,
    ekv = 0,
    mila = 0,
  } = props
  const LOGISTIC = Math.max(logistic, 0)
  const FF = Math.max(ff, 0)
  const KOEF = Math.max(coef, 0)
  const NALOG = Math.max(tax, 0)
  const MIN_CONST_PRIBL = Math.max(profit, 0)
  const MARZA = Math.max(margin, 0)
  const SALE = Math.max(sale, 0) / 100
  const PRIEMKA = Math.max(priemka, 0)
  const EKV = Math.max(ekv, 0)
  const MILA = Math.max(mila, 0)

  const PRIBL = Math.max(MARZA * price, MIN_CONST_PRIBL)

  const KASSA = (PRIBL + FF + price) / (1 - NALOG)

  const currentPrice = Math.ceil(
    (KASSA + LOGISTIC + PRIEMKA) / (1 - KOEF - EKV - MILA),
  )

  return {
    price: currentPrice,
    old_price: Math.ceil(currentPrice / (1 - SALE)),
  }
}
