export const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <g>
          <path d="M12 11a1 1 0 0 1 1 1v4.067a1 1 0 0 1-2 0V12a1 1 0 0 1 1-1ZM12 9.99a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}
