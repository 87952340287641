import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

type infoDataType = {
  vendorCode: string
  amountDistr: string | number
  nameDistr: string
  priceDistr: string | number
}
type initialStateType = {
  infoData: infoDataType[]
}

const initialState: initialStateType = {
  infoData: [],
}
const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setInfoDataByVendorCode: (state, action: PayloadAction<infoDataType[]>) => {
      state.infoData = action.payload
    },
  },
})

export const selectInfo = (state: RootState) => state.info.infoData

export default infoSlice.reducer

export const { setInfoDataByVendorCode } = infoSlice.actions
