import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export type PriceType = {
  margin?: number
  profit?: number
  logistic?: number
  ff?: number
  coef?: number
  tax?: number
  sale?: number
  priemka?: number
  ekv?: number
  mila?: number
}

type ShopSettingsType = {
  price: PriceType
}

type ActiveShopsType<T = "ICON" | "KATREN" | "KATREN_OZON"> = T[]

type initialStateType = {
  [key in "ICON" | "KATREN" | "KATREN_OZON"]?: ShopSettingsType
} & {
  activeShops: ActiveShopsType
}

const initialState: initialStateType = {
  activeShops: [],
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPriceParams: (
      state,
      action: PayloadAction<{
        shop: "ICON" | "KATREN" | "KATREN_OZON"
        price: PriceType
      }>,
    ) => {
      state[action.payload.shop] = {
        ...state[action.payload.shop],
        price: action.payload.price,
      }
    },
    setSettingsState: (state, action: PayloadAction<initialStateType>) => {
      Object.keys(action.payload).forEach((key) => {
        // @ts-expect-error
        state[key] = action.payload[key as keyof initialStateType]
      })
    },
  },
})

export const selectSettings = (state: RootState) => state.settings
export const selectActiveShops = (state: RootState) =>
  state.settings.activeShops

export default settingsSlice.reducer

export const { setSettingsState, setPriceParams } = settingsSlice.actions
